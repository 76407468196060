import { Dialog, Modal, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { HammerLogo, TurkeyFlag, UKFlag } from "../../components/icons";
import {
  Button,
  Section,
  VerticalFlexContainer,
  HorizontalFlexContainer,
  Spacer,
} from "../../components/layout";
import Icons from "../../components/SocialIcons/SocialIcons";
import styles from "../../components/styleConfig.json";
import { MainText } from "../../components/text";
import "./landingSection.css";
import { Particles } from "react-particles-js";
import particlesConfig from "../../particlesjs-config.json";

export default function LandingSection() {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <>
      <Section height="100vh" id={"Home"} justify="center">
        <div className="landing-section">
          <VerticalFlexContainer backgroundColor="red">
            <MainText color={styles.textColor.light} size={isMobile ? 54 : 90}>
              Ragnarok
            </MainText>
            <MainText color={styles.textColor.light} size={isMobile ? 18 : 32}>
              The end of rugging is nigh! Prepare for Valhalla with heavy
              pockets!
            </MainText>
            <Spacer height="30px" />

            <div style={{ paddingBottom: "20px" }}>
              <Icons color="#dedede" />
            </div>
            <HorizontalFlexContainer
              justify={isMobile ? "center" : "flex-start"}
            >
              <Button
                width={isMobile ? "150px" : "200px"}
                height="60px"
                onClick={() => {
                  window.open(
                    "https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x1717cc260bacb7841b37fed8d06846101b31e5c8"
                  );
                }}
              >
                <MainText size={24} color={styles.textColor.light}>
                  BUY
                </MainText>
              </Button>
              <Spacer width={"25px"} />
              <Button
                width={isMobile ? "150px" : "200px"}
                height="60px"
                secondary
                onClick={() => {
                  setOpen(true);
                }}
              >
                <MainText size={24} color={styles.textColor.light}>
                  TELEGRAM
                </MainText>
              </Button>
            </HorizontalFlexContainer>

            {/* <div style={{ paddingTop: "12px" }}>
            <Icons color="#dedede" />
          </div> */}
          </VerticalFlexContainer>
          <HammerLogo size={isMobile ? 400 : 640} />
        </div>
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            zIndex: 0,
            // border: "1px solid red",
          }}
        >
          <Particles height="100vh" width="100%" params={particlesConfig} />
        </div>
      </Section>
      <TelegramModal open={open} setOpen={setOpen} />
    </>
  );
}

function TelegramModal({ open, setOpen }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: {
          borderRadius: 8,
          padding: 24,
          backgroundColor: styles.background.tertiaryColor,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <MainText color={styles.textColor.light}>Choose your language:</MainText>
      <div
        onClick={() => {
          window.open("https://t.me/ragnaroktoken");
        }}
      >
        <UKFlag size={120} />
      </div>
      <div
        onClick={() => {
          window.open("https://t.me/TurkishRagnarokToken");
        }}
      >
        <TurkeyFlag size={120} />
      </div>
    </Dialog>
  );
}

import React from "react";
export default function HorizontalFlexContainer({
  flex,
  children,
  height,
  width,
  align,
  justify,
  paddingHorizontal,
  paddingVertical,
  backgroundColor,
  border,
  maxWidth,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: flex,
        margin: 0,
        height: height,
        width: width,
        maxWidth: maxWidth,
        alignItems: align,
        justifyContent: justify,
        padding: `${paddingVertical} ${paddingHorizontal}`,
        backgroundColor: backgroundColor,
        border,
      }}
    >
      {children}
    </div>
  );
}

HorizontalFlexContainer.defaultProps = {
  align: "center",
  justify: "center",
  paddingHorizontal: "0px",
  paddingVertical: "0px",
};

import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { MainText } from ".";
import styles from "../styleConfig.json";

export default function SectionTitle({ children, light }) {
  const isSmallMobile = useMediaQuery("(max-width: 600px)");
  return (
    <MainText
      size={isSmallMobile ? 44 : 54}
      color={light ? styles.textColor.light : styles.textColor.dark}
      // fontFamily={styles.text.title.fontFamily}
    >
      {children}
    </MainText>
  );
}

import React, { useState } from "react";
import {
  Button,
  HorizontalFlexContainer,
  Section,
  VerticalFlexContainer,
  Spacer,
} from "../components/layout";
import { MainText } from "../components/text";
import styles from "../components/styleConfig.json";
import {
  FoxIcon,
  BNBIcon,
  PancakeIcon,
  AmountIcon,
  SlippageIcon,
  SwapIcon,
  TreasureIcon,
} from "../components/icons";
import "./HowToBuy.css";
import { useMediaQuery } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export default function HowToBuy() {
  const [selected, setSelected] = useState(1);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const stepData = [
    {
      icon: FoxIcon,
      // smallIcon: BNBIcon,
      step: 1,
      steps:
        "Install MetaMask browser extension on your computer from metamask.io and create a wallet",
      title: "Wallet",
      action: () => window.open("https://metamask.io/download.html"),
      actionText: "Install MetaMask",
    },
    {
      icon: BNBIcon,
      // smallIcon: BNBIcon,
      step: 2,
      steps: "Purchase BNB using your favourite method, i.e Binance",
      title: "Load Up",
      action: () =>
        window.open(
          "https://www.binance.com/en/buy-sell-crypto?channel=hzBankcard&fiat=USD"
        ),
      actionText: "Purchase BNB",
    },
    {
      icon: PancakeIcon,
      // smallIcon: PancakeIcon,
      step: 3,
      steps:
        "Open PancakeSwap. Go to the trade tab and type in our contract address: 0x1717cc260bacb7841b37fed8d06846101b31e5c8",
      title: "Open Exchange",
      action: () =>
        window.open(
          "https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x1717cc260bacb7841b37fed8d06846101b31e5c8"
        ),
      actionText: "Swap for RGNK",
    },
    {
      icon: AmountIcon,
      // smallIcon: BNBIcon,
      step: 4,
      steps: "Enter the amount of BNB you wish to swap with",
      title: "Enter Amount",
    },
    {
      icon: SlippageIcon,
      // smallIcon: BNBIcon,
      step: 5,
      steps:
        "Set slippage to 11%. This is done to ensure that your transaction completes and you don't miss out",
      title: "Set Slippage",
    },
    {
      icon: SwapIcon,
      // smallIcon: BNBIcon,
      step: 6,
      steps:
        "Press the Trade button. Then make sure to confirm the transaction in your wallet",
      title: "Swap",
    },
    {
      icon: TreasureIcon,
      // smallIcon: BNBIcon,
      step: 7,
      steps:
        "Join the community and enjoy your gains. Valhalla awaits you all!",
      title: "Join In",
      action: () => window.open("https://t.me/ragnaroktoken"),
      actionText: "Join the community",
    },
  ];
  const activeStep = stepData.filter((d) => d.step === selected)[0];

  function next() {
    if (selected === stepData.length) {
      return;
    } else {
      setSelected((selected) => selected + 1);
    }
  }

  function previous() {
    if (selected === 1) {
      return;
    } else {
      setSelected((selected) => selected - 1);
    }
  }

  return (
    <Section
      subtitle="How do i get it?"
      title="How To Buy"
      id={"Buy"}
      height="630px"
      align="center"
    >
      <Spacer height={isMobile ? "10px" : "20px"} />
      <HorizontalFlexContainer
        height="70px"
        align="center"
        paddingHorizontal={24}
        justify="space-between"
      >
        <div onClick={() => previous()} className="h2bscrollbtn">
          <ChevronLeftIcon style={{ color: "#dedede", fontSize: 30 }} />
        </div>
        <Spacer width="8px" />
        {isMobile ? (
          <HorizontalFlexContainer width="60px">
            <MainText size={20} color={styles.textColor.light}>
              Step {selected}
            </MainText>
          </HorizontalFlexContainer>
        ) : (
          stepData.map((step) => {
            return (
              <Counter
                index={step.step}
                onClick={(val) => setSelected(val)}
                selected={selected}
                icon={step.smallIcon}
              />
            );
          })
        )}
        <Spacer width="8px" />
        <div onClick={() => next()} className="h2bscrollbtn">
          <ChevronRightIcon style={{ color: "#dedede", fontSize: 30 }} />
        </div>
      </HorizontalFlexContainer>
      <LongDescription
        index={activeStep.step}
        title={activeStep.title}
        description={activeStep.steps}
        icon={activeStep.icon}
        action={activeStep.action}
        actionText={activeStep.actionText}
      />
      <Spacer height="30px" />
    </Section>
  );
}

function Counter({ onClick, index, selected, icon }) {
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <div
      className={
        index === selected ? "h2bcounter h2bcounter-active" : "h2bcounter"
      }
      onClick={() => onClick(index)}
    >
      {icon ? (
        icon({ grey: selected !== index, size: isMobile ? 36 : 32 })
      ) : (
        <MainText color={selected === index ? "#DEDEDE" : "#808285"}>
          {index}
        </MainText>
      )}
    </div>
  );
}

function LongDescription({
  description,
  title,
  index,
  icon,
  action,
  actionText,
}) {
  const isMobile = useMediaQuery("(max-width: 900px)");
  if (isMobile) {
    return LongDescriptionMobile({
      description,
      title,
      index,
      icon,
      action,
      actionText,
    });
  } else {
    return LongDescriptionDesktop({
      description,
      title,
      index,
      icon,
      action,
      actionText,
    });
  }
}

function LongDescriptionMobile({
  description,
  title,
  icon,
  action,
  actionText,
}) {
  return (
    <div style={{ minWidth: 300, maxWidth: 500, padding: 24 }}>
      {/* <HorizontalFlexContainer width="450px"> */}
      <VerticalFlexContainer
        align="center"
        width="450px"
        paddingHorizontal={24}
        paddingVertical={24}
      >
        {icon({ size: 150 })}
        <MainText size={40} color={styles.textColor.light} align="center">
          {`${title}`}
        </MainText>
        <div
          style={{ maxWidth: "350px", display: "flex", alignItems: "center" }}
        >
          <MainText size={20} color={styles.textColor.light} align="center">
            {description}
          </MainText>
        </div>
        {action && (
          <div style={{ paddingTop: 32 }}>
            <Button
              secondary
              width="200px"
              height="60px"
              onClick={() => action()}
            >
              <MainText color={styles.textColor.light}>{actionText}</MainText>
            </Button>
          </div>
        )}
      </VerticalFlexContainer>
    </div>
  );
}
function LongDescriptionDesktop({
  description,
  title,
  index,
  icon,
  action,
  actionText,
}) {
  return (
    <div style={{ minWidth: 300, maxWidth: 700, padding: 24 }}>
      <HorizontalFlexContainer align="flex-start">
        {icon({ size: 150 })}
        <Spacer width="50px" />
        <VerticalFlexContainer width="550px">
          <MainText size={40} color={styles.textColor.light} align="left">
            {`Step ${index}. ${title}`}
          </MainText>
          <MainText size={20} color={styles.textColor.light} align="left">
            {description}
          </MainText>
          {action && (
            <div style={{ paddingTop: 32 }}>
              <Button
                secondary
                width="200px"
                height="60px"
                onClick={() => action()}
              >
                <MainText color={styles.textColor.light}>{actionText}</MainText>
              </Button>
            </div>
          )}
        </VerticalFlexContainer>
      </HorizontalFlexContainer>
    </div>
  );
}

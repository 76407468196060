import React from "react";
import { useScroll } from "../../App";
import logo from "../../assets/tempLogo.png";
import { HammerLogo } from "../icons";
import "./Navbar.css";
export default function Navbar() {
  const [colorClass, setColorClass] = React.useState("navbar-dark");
  const [color, setColor] = React.useState(undefined);
  const scroll = useScroll();

  React.useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        let href = document.querySelector(anchor.getAttribute("href"));
        scroll.scrollTo(
          window.pageYOffset + href?.getBoundingClientRect().top - 70
        );
      });
    });
  }, []);

  React.useEffect(() => {
    console.log(scroll.scrollTop);
    const diff = scroll.scrollTop - 30;
    if (diff > 0) {
      setColor("#14132f");
    } else {
      setColor(undefined);
    }
  }, [scroll.scrollTop]);

  return (
    <nav
      id="navbar"
      className={`navbar navbar-expand-lg ${colorClass}  fixed-top`}
      style={{
        backgroundColor: color,
      }}
    >
      <a className="navbar-brand ml-3" href="/">
        <HammerLogo size={72} />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item active">
            <a className="nav-link" href="#Home">
              Home
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Vision">
              Vision
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Roadmap">
              Roadmap
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Tokenomics">
              Tokenomics
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Buy">
              How to buy
            </a>
          </li>
          <li className="nav-item active">
            <a className="nav-link" href="#Graph">
              Graph
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

import React, { useState } from "react";
import {
  Button,
  HorizontalFlexContainer,
  Panel,
  Section,
  Spacer,
  VerticalFlexContainer,
} from "../../components/layout";
import { MainText } from "../../components/text";
import styles from "../../components/styleConfig.json";
import { FoxIcon } from "../../components/icons";
import "./Roadmap.css";
import "../HowToBuy.css";
import { useMediaQuery } from "@material-ui/core";

export default function Roadmap() {
  const [selected, setSelected] = useState(1);
  const isMobile = useMediaQuery("(max-width: 900px)");

  const descriptionData = [
    {
      title: "Contract Released",
      short: "Release Contract",
      long: "Ragnarok has begun. The end is nigh for scams, rugs and honeypots!",
      index: 1,
      completed: true,
      completionDate: "April 2021",
    },
    {
      title: "Refine Our Goals",
      short: "Refine our use case",
      long: "Before our holders can begin their preparations for what it is to come, they must know what it is they are preparing for. Our community have confirmed their need for a rock solid auditing platform for investments in the crypto-space.",
      index: 2,
      completed: true,
      completionDate: "April 2021",
    },
    {
      title: "Recruiting",
      short: "Finalize Team",
      long: "The best projects are made by the best people. We have made sure that our community is being provided for by the best and most brilliant designers, developers, marketers and investors.",
      index: 3,
      completed: true,
      completionDate: "May 2021",
    },
    {
      title: "Marketing",
      short: "Engage a wider community",
      long: "Ragnarok is available to all those prepared to engage with us. Our socials and website are now live but we will continue to interact with influencers and forum users alike.",
      index: 4,
      completed: false,
      completionDate: "Ongoing",
    },
    {
      title: "Planning",
      short: "Desiging our platform",
      long: "Creating an auditing platform for the vast world of new and exciting investment opportunities will be no easy task. Our dedicated team of professional software developers have already begun their work deciding how to bring this to you.",
      index: 5,
      completed: false,
      completionDate: "May/June 2021",
    },
    {
      title: "The Future",
      short: "More to come ...",
      long: "It is still early.... :) The Ragnarok team will always be looking for how to better ourselves for those who depend on us. Watch this space!",
      index: 6,
      completed: false,
      completionDate: "June 2021 +",
    },
  ];
  const activeDescription = descriptionData.filter(
    (d) => d.index === selected
  )[0];

  return (
    <Section
      subtitle="How will we reach Valhalla?"
      title="Road To Ragnarok"
      height={isMobile ? "700px" : "800px"}
      id={"Roadmap"}
    >
      <Spacer height="36px" />
      {/* {isMobile ? (
        <Mobile
          activeDescription={activeDescription}
          descriptionData={descriptionData}
          setSelected={setSelected}
          selected={selected}
        />
      ) : ( */}
      <Desktop
        isMobile={isMobile}
        activeDescription={activeDescription}
        descriptionData={descriptionData}
        setSelected={setSelected}
        selected={selected}
      />
      {/* )} */}
      <Spacer height="60px" />
    </Section>
  );
}

// function Mobile({ activeDescription, descriptionData, setSelected, selected }) {
//   function next() {
//     if (selected === descriptionData.length) {
//       return;
//     } else {
//       setSelected((selected) => selected + 1);
//     }
//   }

//   function previous() {
//     if (selected === 1) {
//       return;
//     } else {
//       setSelected((selected) => selected - 1);
//     }
//   }
//   return (
//     <>
//       <VerticalFlexContainer align="center">
//         <HorizontalFlexContainer
//           height="70px"
//           align="center"
//           paddingHorizontal={24}
//           justify="space-between"
//         >
//           <div onClick={() => previous()} className="h2bscrollbtn"></div>
//           <Spacer width="8px" />
//           <HorizontalFlexContainer width="60px">
//             <MainText size={20} color={styles.textColor.light}>
//               Step {selected}
//             </MainText>
//           </HorizontalFlexContainer>
//           <Spacer width="8px" />
//           <div
//             onClick={() => next()}
//             className="h2bscrollbtn h2bscrollbtn-invert"
//           ></div>
//         </HorizontalFlexContainer>
//         <LongDescription
//           index={activeDescription.index}
//           title={activeDescription.title}
//           description={activeDescription.long}
//           icon={FoxIcon}
//           completionDate={activeDescription.completionDate}
//         />
//       </VerticalFlexContainer>
//     </>
//   );
// }

function Desktop({
  isMobile,
  descriptionData,
  selected,
  setSelected,
  activeDescription,
}) {
  return (
    <HorizontalFlexContainer
      height={isMobile ? "450px" : "500px"}
      width="90%"
      maxWidth="1200px"
      justify="center"
      align="flex-start"
    >
      {!isMobile && (
        <VerticalFlexContainer
          height="100%"
          maxWidth="1200px"
          justify="space-around"
          align="flex-end"
          flex={1}
        >
          {descriptionData.map((description) => {
            return (
              <ShortDescription
                completed={description.completed}
                index={description.index}
                shortDescription={description.short}
                onClick={(val) => setSelected(val)}
                selected={selected}
                completionDate={description.completionDate}
              />
            );
          })}
        </VerticalFlexContainer>
      )}
      <VerticalFlexContainer
        height={isMobile ? `${descriptionData.length * 70}px` : "100%"}
        justify="space-around"
        align="center"
      >
        {descriptionData.map((description) => {
          return (
            <Counter
              onClick={(val) => setSelected(val)}
              completed={description.completed}
              index={description.index}
              selected={selected}
            />
          );
        })}
      </VerticalFlexContainer>
      <VerticalFlexContainer
        flex={1}
        align="center"
        maxWidth={isMobile && "250px"}
      >
        <LongDescription
          index={activeDescription.index}
          title={activeDescription.title}
          description={activeDescription.long}
          icon={FoxIcon}
          completionDate={activeDescription.completionDate}
        />
      </VerticalFlexContainer>
    </HorizontalFlexContainer>
  );
}

function ShortDescription({
  completed,
  completionDate,
  shortDescription,
  index,
  onClick,
  selected,
}) {
  return (
    <HorizontalFlexContainer width="100%" justify="flex-end">
      <VerticalFlexContainer>
        <MainText color={styles.textColor.light} align="right">
          {shortDescription}
        </MainText>
        <MainText align="right" color={styles.textColor.purple} size={18}>
          {completionDate}
        </MainText>
      </VerticalFlexContainer>
      <Spacer width="48px" />
    </HorizontalFlexContainer>
  );
}

function Counter({ onClick, index, selected }) {
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <div
      className={
        index === selected ? "rmcounter rmcounter-active" : "rmcounter"
      }
      style={
        isMobile
          ? {
              height: "48px",
              width: "48px",
              // padding: "5px",
            }
          : {}
      }
      onClick={() => onClick(index)}
    >
      <HorizontalFlexContainer width="100%" height="100%">
        <MainText color={styles.textColor.light} size={30}>
          {index}
        </MainText>
      </HorizontalFlexContainer>
    </div>
  );
}

function LongDescription({
  completionDate,
  description,
  title,
  index,
  icon,
  action,
  actionText,
}) {
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <div
      style={{
        // minWidth: 300,
        padding: 24,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <HorizontalFlexContainer
        width={isMobile ? "200px" : "450px"}
        justify="center"
      >
        <VerticalFlexContainer
          align="center"
          paddingHorizontal={24}
          paddingVertical={24}
          width="100%"
        >
          <MainText
            size={isMobile ? 36 : 54}
            color={styles.textColor.light}
            align="center"
          >
            {title}
          </MainText>
          <MainText align="right" color={styles.textColor.purple} size={22}>
            {completionDate}
          </MainText>
          <HorizontalFlexContainer paddingVertical="24px">
            <MainText color={styles.textColor.light} size={72}>
              {index}
            </MainText>
          </HorizontalFlexContainer>
          <MainText
            size={isMobile ? 20 : 24}
            color={styles.textColor.light}
            align="center"
          >
            {description}
          </MainText>
          {action && (
            <div style={{ padding: 16 }}>
              <Button
                secondary
                width="200px"
                height="60px"
                onClick={() => action()}
              >
                <MainText color={styles.textColor.light}>{actionText}</MainText>
              </Button>
            </div>
          )}
        </VerticalFlexContainer>
      </HorizontalFlexContainer>
    </div>
  );
}

import React from "react";
import {
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  TikTokIcon,
} from "../../components/icons";
import { HorizontalFlexContainer } from "../../components/layout";
import "./socialIcons.css";

export default function Icons({ color }) {
  const iconSize = 25;
  const socialMedia = [
    {
      link: "https://t.me/ragnaroktoken",
      logo: <TelegramIcon color={color} size={iconSize} />,
    },
    {
      link: "https://twitter.com/Ragnarok_Token",
      logo: <TwitterIcon color={color} size={iconSize} />,
    },
    {
      link: "https://www.reddit.com/r/RagnarokToken/",
      logo: <RedditIcon color={color} size={iconSize} />,
    },
    {
      link: "https://www.tiktok.com/@ragnarok_token?lang=en",
      logo: <TikTokIcon color={color} size={iconSize} />,
    },
  ];
  return (
    <HorizontalFlexContainer justify="flex-start">
      <div className="icon-container">
        {socialMedia.map((val, i) => (
          <div
            className="icon"
            key={i}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(val.link);
            }}
            style={{
              zIndex: 1000,
            }}
          >
            {val.logo}
          </div>
        ))}
      </div>
    </HorizontalFlexContainer>
  );
}

import React from "react";
import styles from "../styleConfig.json";

export default function MainText({
  children,
  color,
  size,
  align,
  fontFamily,
  fontWeight,
}) {
  return (
    <span
      style={{
        color: color,
        fontSize: size,
        textAlign: align,
        fontFamily: fontFamily,
        fontWeight: fontWeight,
        lineHeight: 1.2,
      }}
    >
      {children}
    </span>
  );
}

MainText.defaultProps = {
  color: styles.textColor.dark,
  size: styles.text.subtitle.fontSize,
  fontFamily: styles.text.body.fontFamily,
  fontWeight: styles.text.body.fontWeight,
  align: "left",
};

import React from "react";
import hammerLogo from "../../../assets/hammer-logo.png";

export default function HammerLogo({ size }) {
  const offset = 64 * (size / 492);
  return (
    <div
      style={{
        // backgroundColor: "red",
        maxWidth: `${size}px`,
        width: `${size}px`,
        position: "relative",
        maxWidth: "100%",
      }}
    >
      <img
        src={hammerLogo}
        style={{
          width: "80%",
          left: offset,
          position: "relative",
        }}
      />
    </div>
  );
}

import React from "react";
import styles from "../components/styleConfig.json";
import { makeStyles } from "@material-ui/core";
import { Panel, Section, VerticalFlexContainer } from "../components/layout";
import { SectionTitle } from "../components/text";

export default function TeamSection() {
  return (
    <Section title="Meet the Team" height="60vh" id="Team">
      <TeamMember />
      <TeamMember />
      <TeamMember />
      <TeamMember />
    </Section>
  );
}

function TeamMember() {
  const classes = useStyles();
  return (
    <Panel>
      <VerticalFlexContainer
        paddingVertical="20px"
        paddingHorizontal="20px"
        align="center"
      >
        <img
          className={classes.image}
          src={"https://i.ytimg.com/vi/dQw4w9WgXcQ/maxresdefault.jpg"}
        />
      </VerticalFlexContainer>
    </Panel>
  );
}

const useStyles = makeStyles({
  image: {
    width: 100,
    height: 100,
    objectFit: "stretch",
    borderRadius: 50,
  },
});

import React from "react";
import HorizontalFlexContainer from "../HorizontalFlexContainer";
import "./button.css";

export default function Button({ children, onClick, ...props }) {
  return (
    <button
      className={props.secondary ? "btn btn-scndry" : "btn"}
      style={{
        width: props.width,
        height: props.height,
        backgroundColor: props.backgroundColor,
      }}
      onClick={onClick}
    >
      <HorizontalFlexContainer>{children}</HorizontalFlexContainer>
    </button>
  );
}

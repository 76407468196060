import React from "react";
import { VerticalFlexContainer } from "../index.js";
import { MainText } from "../../text";
import styles from "../../styleConfig.json";
import "./Panel.css";
import Spacer from "../Spacer.js";

export default function Panel({
  icon,
  text,
  width,
  height,
  small,
  superSmall,
}) {
  return (
    <div
      className={small ? "panel panel-small" : "panel"}
      style={{
        width,
        height,
        maxWidth: "220px",
        maxHeight: "240px",
      }}
    >
      <VerticalFlexContainer align="center" height="100%">
        {icon}
        <Spacer height="10px" />
        <MainText
          size={superSmall ? 16 : 20}
          color={styles.textColor.light}
          align="center"
          fontFamily={styles.text.body.fontFamily}
          fontWeight={styles.text.body.fontWeight}
        >
          {text}
        </MainText>
      </VerticalFlexContainer>
    </div>
  );
}

Panel.defaultProps = {
  width: "220px",
  height: "240px",
};

import React from "react";
import icon from "../../assets/BNB.svg";
import iconGrey from "../../assets/BNB-grey.svg";

export default function BNBIcon({ size, grey }) {
  return grey ? (
    <img src={iconGrey} width={size} />
  ) : (
    <img src={icon} width={size} />
  );
}

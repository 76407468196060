import React from "react";
import {
  HorizontalFlexContainer,
  Panel,
  Section,
  Spacer,
  VerticalFlexContainer,
} from "../components/layout";
// import Coin from "../assets/coin.webp";
import { MachineLearningIcon } from "../components/icons";
import { useMediaQuery } from "@material-ui/core";
import LiquidityIcon from "../components/icons/LiquidityIcon";
import ReflectionIcon from "../components/icons/ReflectionIcon";
import CommunityIcon from "../components/icons/CommunityIcon";

export default function TokenDetails() {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const isSmallMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Section subtitle="What is ragnarok?" title="Tokenomics" id="Tokenomics">
      {isMobile ? (
        <VerticalFlexContainer align="space-around">
          <Panel
            width={"40vw"}
            height={"45vw"}
            small
            superSmall={isSmallMobile}
            icon={<LiquidityIcon size={"80%"} />}
            text="8% Auto-liquidity per transaction"
          />
          <Panel
            width={"40vw"}
            height={"45vw"}
            small
            superSmall={isSmallMobile}
            icon={<ReflectionIcon size={"80%"} />}
            text="2% Reflection per transaction"
          />
          <Panel
            width={"40vw"}
            height={"45vw"}
            small
            superSmall={isSmallMobile}
            icon={<CommunityIcon size={"80%"} />}
            text="100% Community owned"
          />
        </VerticalFlexContainer>
      ) : (
        <HorizontalFlexContainer>
          <Panel
            icon={<LiquidityIcon size={140} />}
            text="8% Auto-liquidity per transaction"
          />
          <Panel
            icon={<ReflectionIcon size={140} />}
            text="2% Reflection per transaction"
          />
          <Panel
            icon={<CommunityIcon size={140} />}
            text="100% Community owned"
          />
        </HorizontalFlexContainer>
      )}
      <Spacer height="80px" />
    </Section>
  );
}

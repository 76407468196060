import React from "react";
import { Section, VerticalFlexContainer, Spacer } from "../components/layout";

const iframe =
  '<iframe style="position:absolute;top:0;left:0;width:100%;height:100%; scrolling="no" title="fx." src="https://charts.bogged.finance/?token=0x1717cc260bacb7841b37fed8d06846101b31e5c8" frameborder="no" allowtransparency="true" allowfullscreen="true"/>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

export default function GraphSection() {
  return (
    <div>
      <Section
        subtitle="How is Ragnarok doing?"
        title="Live Chart"
        height="150px"
        width="100%"
        id="Graph"
      />
      <VerticalFlexContainer align="center">
        <div
          style={{
            position: "relative",
            maxWidth: "1200px",
            width: "90%",
            height: "140vh",
            maxHeight: "900px",
          }}
          id="Graph"
        >
          <Iframe iframe={iframe} />
        </div>
        <Spacer height="50px" />
      </VerticalFlexContainer>
    </div>
  );
}

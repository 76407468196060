import React from "react";
import { MainText } from ".";
import styles from "../styleConfig.json";

export default function SectionSubtitle({ children }) {
  return (
    <MainText
      size={styles.text.subtitle.fontSize}
      color={styles.textColor.purple}
      // fontFamily={styles.text.title.fontFamily}
    >
      {children.toUpperCase()}
    </MainText>
  );
}

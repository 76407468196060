import React from "react";
import { HorizontalFlexContainer, Section } from "../layout";
import { MainText } from "../text";
import styles from "../../components/styleConfig.json";
import "./footer.css";
import Icons from "../SocialIcons/SocialIcons";

export default function Footer() {
  return (
    <Section backgroundColor="#14132f">
      <HorizontalFlexContainer
        align="center"
        justify="space-between"
        width="100%"
      >
        <MainText color={styles.textColor.light}>
          Ragnarok token - [ROK]
        </MainText>
        <Icons color={styles.textColor.light} />
      </HorizontalFlexContainer>
    </Section>
  );
}

import React from "react";
import {
  Panel,
  Section,
  VorizontalFlexContainer,
  HorizontalFlexContainer,
  Button,
  Spacer,
  VerticalFlexContainer,
} from "../components/layout";
import { MainText } from "../components/text";
import styles from "../components/styleConfig.json";
import { MachineLearningIcon, KYCIcon } from "../components/icons";
import DataVisualisationIcon from "../components/icons/DataVisualisationIcon";
import { useMediaQuery } from "@material-ui/core";

export default function UseCaseSection() {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const isSmallMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Section
      subtitle="What do we do?"
      title="Ragnarok Vision"
      id="Vision"
      align="center"
      // height="800px"
    >
      <Spacer height="35px" />
      <div
        style={{
          width: "60%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MainText size={22} color={styles.textColor.light} align="center">
          In Norse mythology, Ragnarok is the battle at the end of the world.
          For us, Ragnarok is the end of scam tokens and rugpulls
        </MainText>
      </div>
      <Spacer height="10px" />
      {isMobile ? (
        <VerticalFlexContainer align="center">
          <Panel
            width={isSmallMobile ? "50vw" : "40vw"}
            height={isSmallMobile ? "55vw" : "45vw"}
            icon={<MachineLearningIcon size={"80%"} />}
            text="Machine learning auditing"
          />
          <Panel
            width={isSmallMobile ? "50vw" : "40vw"}
            height={isSmallMobile ? "55vw" : "45vw"}
            icon={<DataVisualisationIcon size={"80%"} />}
            text="Data visualisation tools"
          />
          <Panel
            width={isSmallMobile ? "50vw" : "40vw"}
            height={isSmallMobile ? "55vw" : "45vw"}
            icon={<KYCIcon size={"80%"} />}
            text="Auditing and KYC"
          />
        </VerticalFlexContainer>
      ) : (
        <HorizontalFlexContainer>
          <Panel
            icon={<MachineLearningIcon size={140} />}
            text="Machine learning auditing"
          />
          <Panel
            icon={<DataVisualisationIcon size={140} />}
            text="Data visualisation tools"
          />
          <Panel icon={<KYCIcon size={140} />} text="Auditing and KYC" />
        </HorizontalFlexContainer>
      )}
      <Spacer height={isMobile ? "20px" : "50px"} />
      <Button
        width="200px"
        height="60px"
        onClick={() => {
          window.open(
            "https://ragnarok-token.medium.com/ragnarok-token-day-2-and-3-summary-deep-dive-into-our-use-case-af248f1d151d"
          );
        }}
      >
        <MainText size={24} color={styles.textColor.light}>
          READ MORE
        </MainText>
      </Button>
      <Spacer height="100px" />
    </Section>
  );
}

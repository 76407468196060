import React from "react";
import {
  HorizontalFlexContainer,
  VerticalFlexContainer,
  Spacer,
} from "./index";
import styles from "../styleConfig.json";
import { SectionSubtitle, SectionTitle } from "../text";
import { useMediaQuery } from "@material-ui/core";

export default function Section({
  children,
  backgroundColor,
  justify,
  align,
  height,
  position,
  subtitle,
  title,
  id,
}) {
  const isMobile = useMediaQuery("(max-width: 900px)");
  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        width: "100%",
        position,
      }}
      id={id}
    >
      <VerticalFlexContainer
        paddingVertical={isMobile ? "0px" : "20px"}
        paddingHorizontal={isMobile ? "10px" : "80px"}
        height={height}
        justify={justify}
        align={align}
        width="100%"
      >
        {subtitle && (
          <div>
            <Spacer height="15px" />
            <SectionSubtitle>{subtitle}</SectionSubtitle>
          </div>
        )}
        {title && (
          <div>
            <SectionTitle light>{title}</SectionTitle>
          </div>
        )}
        {children}
      </VerticalFlexContainer>
    </div>
  );
}

Section.defaultProps = {
  position: "default",
  align: "center",
};

import "./App.css";
import { PageWrapper } from "./components/layout";
import {
  GraphSection,
  HowToBuySection,
  LandingSection,
  Roadmap,
  TeamSection,
  TokenDetails,
} from "./Sections";
import Scrollbars from "react-custom-scrollbars";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Navbar from "./components/Navbar/Navbar";
import UseCaseSection from "./Sections/UseCaseSection";
import { Footer } from "./components";

const ScrollContext = createContext(null);

export function useScroll() {
  const { scrollRef, scrollTop } = useContext(ScrollContext);
  const scrollTo = useCallback(
    (value) => {
      if (!scrollRef || !scrollRef.current) {
        return;
      }
      scrollRef.current.view.scroll({
        top: scrollRef.current.getScrollTop() + value,
        behavior: "smooth",
      });
    },
    [scrollRef, scrollRef.current]
  );

  return { scroll: scrollRef.current, scrollTo, scrollTop };
}

export const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addEventListener(handler);
    return () => mediaMatch.removeEventListener(handler);
  });
  return matches;
};

function App() {
  const [top, setTop] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const scrollRef = useRef(null);

  return (
    <ScrollContext.Provider value={{ scrollRef, scrollTop }}>
      <div style={{ height: "100vh", width: "100vw" }}>
        <Scrollbars
          ref={scrollRef}
          onUpdate={(event) => {
            setScrollTop(event.scrollTop);
            setTop(event.top);
          }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          thumbMinSize={30}
        >
          <PageWrapper>
            <Navbar top={top} />
            <LandingSection />
            <UseCaseSection />
            <Roadmap />
            <TokenDetails />
            {/* <TeamSection /> */}
            <HowToBuySection />
            <GraphSection />
            <Footer />
          </PageWrapper>
        </Scrollbars>
      </div>
    </ScrollContext.Provider>
  );
}

export default App;
